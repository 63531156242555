import React, { useState } from 'react';
import classNames from 'classnames';
import './SideBarToggle.css';
//import navigateBeforeRight from '../../images/navigateBeforeRight.svg';
//import navigateBeforeLeft from '../../images/navigateBeforeLeft.svg';


function SideBarToggle(props) {
  const [icon, setIcon] = useState(false);

  const onSetIcon = () => {
    setIcon(!icon);
    props.onSidebarToggle(icon);
  };

  const sideBarToggleClasses = classNames({sideBarToggle: !icon}, { sideToggled: icon});

  return (
    <div className='side-bar-toggle' onClick={onSetIcon}>
      <div className={sideBarToggleClasses} style={{position: 'fixed'}}>
        {/* { !icon ? <i className="fa fa-angle-left"></i> : 
          <i className="fa fa-angle-right"></i> }  */}
        { !icon ? <span className='navigation-right-icon'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path className='color-hover' fill="#219EBC" d="M15.61 7.41L14.2 6l-6 6 6 6 1.41-1.41L11.03 12l4.58-4.59z"/></svg></span> : 
          <span className='navigation-left-icon'><svg fill="#6D6D6D" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#219EBC" d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"/></svg></span>   } 

{/* { !icon ? <img className='navigation-right-icon' src={navigateBeforeRight} alt='left-navigation' /> : 
          <img className='navigation-left-icon' src={navigateBeforeLeft} alt='right-navigation' /> }  */}
      </div>
    </div>
  )
}

export default SideBarToggle;